import debug from 'debug'

import googleTag from '~/client/assets/js/common/google-tag'
import jQuery from '~/client/assets/js/common/jquery'

import {
  getCurrency,
  renderTotalPrice
} from '~/client/assets/js/common/render-data'

const log = debug('analytics')

log('`analytics` is awake')

export function dispatchSummaryEvent (currency = 'GBP', value = 0.00) {
  log('dispatchSummaryEvent')

  const gtag = googleTag()

  gtag('event', 'summary', {
    currency,
    value
  })
}

export function dispatchOptionsSummaryEvent (currency = 'GBP', value = 0.00) {
  log('dispatchOptionsSummaryEvent')

  const gtag = googleTag()

  gtag('event', 'options_summary', {
    currency,
    value
  })
}

export function dispatchImageEvent (direction = 'N/A') {
  log('dispatchImageEvent')

  const gtag = googleTag()

  gtag('event', 'image', {
    direction
  })
}

export function dispatchPrintEvent () {
  log('dispatchPrintEvent')

  const gtag = googleTag()

  gtag('event', 'print')
}

export function dispatchShareEvent () {
  log('dispatchShareEvent')

  const gtag = googleTag()

  gtag('event', 'share')
}

export function dispatchResetEvent () {
  log('dispatchResetEvent')

  const gtag = googleTag()

  gtag('event', 'reset')
}

export function dispatchGenerateLeadEvent (currency = 'GBP', value = 0.00) {
  log('dispatchGenerateLeadEvent')

  const gtag = googleTag()

  gtag('event', 'generate_lead', {
    currency,
    value
  })
}

export function dispatchSelectMenuEvent (key = 'N/A', label = 'N/A') {
  log('dispatchSelectMenuEvent')

  const gtag = googleTag()

  gtag('event', 'select_menu', {
    key,
    label
  })
}

export function dispatchSelectMenuItemEvent (key = 'N/A', label = 'N/A') {
  log('dispatchSelectMenuItemEvent')

  const gtag = googleTag()

  gtag('event', 'select_menu_item', {
    key,
    label
  })
}

export function dispatchSelectMenuListItemEvent (key = 'N/A', label = 'N/A') {
  log('dispatchSelectMenuListItemEvent')

  const gtag = googleTag()

  gtag('event', 'select_menu_list_item', {
    key,
    label
  })
}

export function dispatchSelectMenuViewItemEvent (key = 'N/A', group = 'N/A', label = 'N/A') {
  log('dispatchSelectMenuViewItemEvent')

  const gtag = googleTag()

  gtag('event', 'select_menu_view_item', {
    key,
    group,
    label
  })
}

function handleError ({ code = 'No code defined', message = 'No message defined' }) {
  return `${code}: ${message}`
}

export function handleMenuTopLevelDelegateClick (event) {
  log('handleMenuTopLevelDelegateClick')

  const $ = jQuery()
  const menuItem = $(event.target).closest('li.menu-item')

  const key = menuItem.data('key')
  const label = menuItem.find('> .title h3').text().trim()

  try {
    dispatchSelectMenuEvent(key, label)
  } catch (e) {
    log(handleError(e))
  }
}

export function handleMenuTopLevelOfSubLevelDelegateClick (event) {
  log('handleMenuTopLevelOfSubLevelDelegateClick')

  const $ = jQuery()
  const menuItem = $(event.target).closest('li.menu-item')

  const key = 'packs'
  const label = menuItem.find('> .title h3').text().trim()

  try {
    dispatchSelectMenuEvent(key, label)
  } catch (e) {
    log(handleError(e))
  }
}

export function handleMenuSubLevelDelegateClick (event) {
  log('handleMenuSubLevelDelegateClick')

  const $ = jQuery()
  const menuItem = $(event.target).closest('li.menu-item')

  const key = menuItem.data('key')
  const label = menuItem.find('> span').text().trim()

  try {
    dispatchSelectMenuItemEvent(key, label)
  } catch (e) {
    log(handleError(e))
  }
}

export function handleMenuListItemDelegateClick (event) {
  log('handleMenuListItemDelegateClick')

  const $ = jQuery()
  const menuListItem = $(event.target).closest('li.menu-list-item')

  const key = menuListItem.data('key')
  const label = menuListItem.find('> span').text().trim()

  try {
    dispatchSelectMenuListItemEvent(key, label)
  } catch (e) {
    log(handleError(e))
  }
}

export function handleMenuViewItemDelegateClick (event) {
  log('handleMenuViewItemDelegateClick')

  const $ = jQuery()
  const field = $(event.target)
  const menuViewItem = field.closest('label')

  const key = menuViewItem.closest('[data-key]').data('key')
  const group = menuViewItem.closest('[data-group]').data('group')
  const label = menuViewItem.find('span.name').text().trim()

  try {
    dispatchSelectMenuViewItemEvent(key, group, label)
  } catch (e) {
    log(handleError(e))
  }
}

export function handleSummaryClick () {
  log('handleSummaryClick')

  const currency = getCurrency()
  const totalPrice = renderTotalPrice()

  try {
    dispatchSummaryEvent(currency, totalPrice)
  } catch (e) {
    log(handleError(e))
  }
}

export function handleOptionsSummaryClick () {
  log('handleOptionsSummaryClick')

  const currency = getCurrency()
  const totalPrice = renderTotalPrice()

  try {
    dispatchOptionsSummaryEvent(currency, totalPrice)
  } catch (e) {
    log(handleError(e))
  }
}

export function handleGenerateLeadClick () {
  log('handleGenerateLeadClick')

  const currency = getCurrency()
  const totalPrice = renderTotalPrice()

  try {
    dispatchGenerateLeadEvent(currency, totalPrice)
  } catch (e) {
    log(handleError(e))
  }
}

export function handlePrevClick () {
  log('handlePrevClick')

  try {
    dispatchImageEvent('prev')
  } catch (e) {
    log(handleError(e))
  }
}

export function handleNextClick () {
  log('handleNextClick')

  try {
    dispatchImageEvent('next')
  } catch (e) {
    log(handleError(e))
  }
}

export function handleShareClick () {
  log('handleShareClick')

  try {
    dispatchShareEvent()
  } catch (e) {
    log(handleError(e))
  }
}

export function handlePrintClick () {
  log('handlePrintClick')

  try {
    dispatchPrintEvent()
  } catch (e) {
    log(handleError(e))
  }
}

export function handleResetClick () {
  log('handleResetClick')

  try {
    dispatchResetEvent()
  } catch (e) {
    log(handleError(e))
  }
}

/**
 *  Home (Content Loaded - Default Export)
 */
export default function handleContentLoaded () {
  log('handleContentLoaded')

  const $ = jQuery()

  /**
   *  Menu (Top Level)
   */
  $('#menu > ul')
    .delegate('> li[data-key]', 'click', handleMenuTopLevelDelegateClick)

  /**
   *  Menu (Top Level of Sub Level)
   */
  $('#menu > ul')
    .delegate('> li:not([data-key]):has(> ul > li[data-key])', 'click', handleMenuTopLevelOfSubLevelDelegateClick)

  /**
   *  Menu (Sub Level)
   */
  $('#menu > ul > li > ul')
    .delegate('> li[data-key]', 'click', handleMenuSubLevelDelegateClick)

  /**
   *  Menu List (Item)
   */
  $('#menu-list > ul')
    .delegate('> li[data-key]', 'click', handleMenuListItemDelegateClick)

  /**
   *  Menu View (Item)
   */
  $('#menu-view')
    .delegate('.menu-view-item .panel label input[type=radio]', 'click', handleMenuViewItemDelegateClick)

  /**
   *  Summary
   */
  $('#renderapp:not(.dealership) .view-summary')
    .click(handleSummaryClick)

  /**
   *  Options summary
   */
  $('#renderapp.dealership .view-summary, #renderapp:not(.dealership) button.summary-change')
    .click(handleOptionsSummaryClick)

  /**
   *  Retailer
   */
  $('#renderapp:not(.dealership, .plus, .vr) button.retailer-send')
    .click(handleGenerateLeadClick)

  /**
   *  Previous image
   */
  $('#renderapp button.prev')
    .click(handlePrevClick)

  /**
   *  Next image
   */
  $('#renderapp button.next')
    .click(handleNextClick)

  /**
   *  Share
   */
  $('#renderapp button.share')
    .click(handleShareClick)

  /**
   *  Print
   */
  $('#renderapp button.print')
    .click(handlePrintClick)

  /**
   *  Reset
   */
  $('#renderapp button.reset')
    .click(handleResetClick)
}

{
  const $ = jQuery()

  $(handleContentLoaded)
}
