import debug from 'debug'

const log = debug('common:google-tag')

export default function googleTag () {
  const {
    gtag = function gtag (...args) {
      log(...args)
    }
  } = global

  return gtag
}
